<template>
    <div>
		
		<el-form ref="queryForm" size="small" :inline="true"  label-width="68px">
		  <el-form-item label="名称" prop="keys">
			  <el-input
			    v-model="keys"
			    placeholder="请输入名称"
			    clearable
			    @keyup.enter.native="search"
			  />
		  </el-form-item>
		  
		  <el-form-item label="时间" prop="keys">
			  <el-date-picker style="margin-right: 20px;" type="date" format="yyyy-MM-dd" clearable value-format="yyyy-MM-dd"
								  v-model="startDate" size="small" placeholder="开始日期" >
			  </el-date-picker>
			  <el-date-picker type="date" style="margin-right: 20px;" format="yyyy-MM-dd" clearable value-format="yyyy-MM-dd"
								  v-model="endDate" size="small" placeholder="结束日期" >
			  </el-date-picker>
		  </el-form-item>
		  <el-form-item>
		    <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
		  </el-form-item>
		</el-form>
		
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
				  type="primary"
				  plain
				  icon="el-icon-plus"
				  size="mini"
				  @click="toAdd"
			
				>预约</el-button>
			</el-col>
		</el-row>
		
        <!-- <div class="uni-header">
            <div class="uni-group">
                <div class="uni-title">我的会诊</div>
            </div>
            <div class="uni-group">
				        
				<input class="uni-search" type="text" v-model="keys" @confirm="search" placeholder="请输入" />
				<button class="uni-button" type="default" size="mini" @click="search">搜索</button>
            </div>
			
			
			<div class="uni-group">
				<button class="uni-button" type="primary" size="mini" @click="toAdd">预约</button>
			</div>
			
			
			
        </div> -->
        <div class="uni-container">
			<el-table
				:v-loading="loading"
			      :data="tableData"
			      style="width: 100%">
				  
				  <el-table-column  label="患者" prop="patient_name" width="120" >
					  
					  <template slot-scope="scope">					
						<div v-if="scope.row.patient_name">{{scope.row.patient_name}}</div>
						<div v-else>{{scope.row.name}}</div>
					  </template>
					  
				  </el-table-column>
				  
				  <el-table-column  label="会诊时间" prop="startTime" width="150" />
				  <el-table-column  label="预约医生" prop="doctorName" width="120" align="center" />
				  <el-table-column  label="医院" prop="doctorHospitalName" width="220" align="center" />
				  <el-table-column  label="状态" prop="audit" width="120" align="center">
					  <template slot-scope="scope">		
						<div >{{consultationAudit[scope.row.audit].text}}</div>
					  </template>
					  
				  </el-table-column>
				  <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
				    <template slot-scope="scope">
						  
						  <el-link v-if="scope.row.audit==1" :href="scope.row.meetingUrl" class="el-link--inner"  target="_blank">视频会诊</el-link>
						  <el-button type="primary" @click="openInfo(scope.row)" size="mini" plain>会诊信息</el-button>
						  <el-button type="primary" size="mini"  @click="agreeSub(scope.row)" 
						 v-if="scope.row.audit==0&&thisUser&&scope.row.doctor_id==thisUser.user.id">同意会诊</el-button>
						  <el-button v-if="scope.row.audit==1" @click="writeCaseWindows(scope.row)" size="mini" type="warning" plain>填写报告</el-button>
						  <el-button v-if="scope.row.audit==0" size="mini" @click="remCase(scope.row)" type="warning" plain>取消</el-button>
						  <div v-if="scope.row.audit==2" > {{scope.row.auditContent}}</div>
					</template>
					  
				  </el-table-column> 
				  
				  
			</el-table>
				<!-- 	
            <uni-table :loading="loading" border stripe type="selection" :emptyText="$t('common.empty')" @selection-change="selectionChange">
                <uni-tr>
                    <uni-th width="120">患者</uni-th>
                    <uni-th width="150">会诊时间</uni-th>
                    <uni-th width="120" align="center">预约医生</uni-th>
					<uni-th width="220" align="center">医院</uni-th>
					<uni-th width="120" align="center">状态</uni-th>
					 <uni-th  align="center">操作</uni-th>
                </uni-tr>
                <uni-tr v-for="(item ,index) in tableData" :key="index">
                    <uni-td>
						<div v-if="item.patient_name">{{item.patient_name}}</div>
						<div v-else>{{item.name}}</div>
					</uni-td>
                    <uni-td><div class="name">{{item.startTime}}</div></uni-td>
                    <uni-td>{{item.doctorName}}</uni-td>
					
					<uni-td>{{item.doctorHospitalName}}</uni-td>
					
					<uni-td>{{consultationAudit[item.audit].text}}</uni-td>
					<uni-td>
						
						
import html2canvas from 'html2canvas';

					</uni-td>
                </uni-tr>
            </uni-table> -->
            <div class="uni-pagination-box">
<!--                <uni-pagination show-icon :page-size="pageSize" :current="pageCurrent" :total="total" @change="change" /> -->
            </div>
        </div>
		<!-- #ifndef H5 -->
		
		<!-- #endif -->
		<writeCase :titleWindows="titleWindows" :isWindows="isCaseWindows" :dataId="thisDataId" 
		 :thisDataContent="thisDataContent"  @openWindows="openCaseWindows"></writeCase>
		
		<consultmentInfo :dataId="thisDataId" :isWindows="isInfoWindows" 
		 :titleWindows="titleWindows"
		 @openWindows="openInfoWindows"> </consultmentInfo>
    </div>
</template>

<script>
	
	import config from '@/common/config.js'
	
	import writeCase from './windows/writeCase';
	import consultmentInfo  from './windows/consultmentInfo';
	
    export default {
		 name: "consultment",
		components:{writeCase,consultmentInfo},
        data() {
            return {
				thisUser:config.getLoginInfo(),
				startDate: '',
				endDate: '',
				thisDataContent:'',
				
				isCaseWindows:false,
				thisDataId:'',
				titleWindows:'',
				
				pageCurrent:1,
				keys:'',
				isInfoWindows:false,				
                searchVal: '',
                tableData: [],
				consultationAudit:[
				 {text:'等待专家确认',value:'toBeConfirmed'},{text:'等待开始',value:'notStarted'}
				,{text:'专家拒绝',value:'refused'},{text:'结束',value:'finished'}
				,{text:'投诉',value:'complained'},{text:'结算',value:'settlement'}
				,{text:'取消',value:'cancel'},
				{text:'未支付',value:'noPay'}],
                // 每页数据量
                pageSize: 10,
                // 当前页
               
                // 数据总量
                total: 0,
                loading: false,
				pickerOptionsStart: {
			        disabledDate: time => {
			          const endDateVal = new Date(this.endDate).getTime()
			          if (endDateVal) {
			            return time.getTime() > endDateVal - 0
			          }
			        }
				},
				pickerOptionsEnd: {
						disabledDate: time => {
						  const beginDateVal = new Date(this.startDate).getTime()
						  if (beginDateVal) {
							return time.getTime() < beginDateVal - 0
						  }
						}
				}
            }
        },
        created() {
			console.log("html2canvas()",this.thisUser)
            this.selectedIndexs = []
            this.getData();
			
        },
        methods: {
			openInfoWindows(val){
				this.isInfoWindows=val;
			},
			openInfo(item){
				this.isInfoWindows=true;
				this.thisDataId=item.id;
				
				this.titleWindows=item.name+'-会诊信息'
			},
			//同意会诊
			agreeSub(item){
				var _this=this;
				this.$confirm('您确定同意会诊，确定后不能取消', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
				          
						  var  data={
						  	consultationId:item.id,
						  	userId:this.thisUser.user.id,
						  	status:'notStarted',
						  }
						  
						  config.ajaxAw("/kongtangweishi/api/consultation/auditConsultation",
						  data,function(data){
						  	
						  	_this.$message({  message: '您已确认会诊将于'+item.startTime+"准时开始",type: 'success'});
						  	
							_this.getData();
						  	
						  });
						  
						  
				});
				
				
			},
			//拒绝会诊
			refuseSub(item){
				
			},
			
			//取消病例
			remCase(item){
				//
				// toBeConfirmed("等待专家确认"), notStarted("等待会诊开始"),
				// refused("专家拒绝"), finished("会诊结束"),
				// complained("投诉"),settlement("结算"),cancel("取消"),noPay("未付款");
				
				var _this=this;
				var  data={	consultationId:item.id,	userId:this.thisUser.user.id,	status:'refused',}
				if(item.user_id==this.thisUser.user.id){data.status="cancel";}
				this.$prompt('请问您是什么原因要取消会诊？', '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				}).then(({ value }) => {
				    data.auditContent=value;
					config.ajaxAw("/kongtangweishi/api/consultation/auditConsultation",
					data,function(data){
						_this.$message({  message: '已取消',type: 'success'});
						_this.openWindows=false;
					});
				});
			},
			
			//打开病例模板填写
			writeCaseWindows(item){
				this.isCaseWindows=true;
				this.thisDataId=item.id;
				
				this.thisDataContent=item.resultContent;
				this.titleWindows=item.name+'-病例报告填写'
			},
			
			//病例填写框状态通知
			openCaseWindows(val){
				this.isCaseWindows=val;
				this.getData();
				
				
			},
			
            // 多选处理
            selectedItems() {
                return this.selectedIndexs.map(i => this.tableData[i])
            },
            // 多选
            selectionChange(e) {
                console.log(e.detail.index);
                this.selectedIndexs = e.detail.index
            },
            //批量删除
            delTable() {
                console.log(this.selectedItems());
            },
            // 分页触发
            change(e) {
				this.pageCurrent=e.current;
                this.getData()
            },
            // 搜索
            search() {
				this.pageCurrent=1;
                this.getData()
            },
			toAdd(){
				
				this.$router.push('/queConsultment');
				// uni.navigateTo({
				// 	url:"/pages/appointment/addConsultment/queConsultment"
				// })
			},
			
            // 获取数据
            getData() {
                this.loading = true
              
				var _this=this;				
				config.ajaxAw("/kongtangweishi/api/consultation/getConsultationList",
				{identity: 'doctor',userId:this.thisUser.user.id,isPage:true,
				 keys:this.keys, startDate:_this.startDate,endDate:_this.endDate,page: this.pageCurrent
				},function(data){
					_this.tableData = data.content
					_this.total = data.totalElements
					_this.loading = false
				})
				
                // this.request({
                //     pageSize: this.pageSize,
                //     pageCurrent: pageCurrent,
                //     value: value,
                //     success: (res) => {
                //         // console.log('data', res);
                //         this.tableData = res.data
                //         this.total = res.total
                //         this.loading = false
                //     }
                // })
            }
           

        }
    }
</script>

<style>
	/* #ifndef H5 */
	page {
		padding-top: 85px;
	}
	/* #endif */
	.el-link--inner{
		    color: #409eff;
			margin: 0px 10px;
	}
</style>
