<template>
	<div>
		<el-dialog :title="titleWindows" :visible.sync="openWindows"
		width="950px" append-to-body>
		
				<el-input
				  type="textarea"
				  placeholder="请输入内容"
				  v-model="content">
				</el-input>
				
				
		<div class="bootom_panel">
			<el-button type="success" @click="subWrite">填写完成</el-button>
			
		</div>
		  
		
		</el-dialog>
		
	</div>
	
</template>

<script>
	import config from '@/common/config.js'
	
export default {
		name: "writeCase",
		props: {
		  titleWindows:{type:String,default:'病例报告填写'},
		  dataId:{type:String,default:""},
		  isWindows: {type: Boolean,default: false,},
		  thisDataContent:{type:String,default:''},
		  
		},
		watch: {
			thisDataContent: {
			  handler(val) {
			    this.content=val;
			  },
			  immediate: true,
			},
		  isWindows: {
		    handler(val) {
		      this.openWindows=val;
		    },
		    immediate: true,
		  },
		  dataId: {
		    handler(val) {
		      
		    },
		    immediate: true,
		  },
		  openWindows: {
		    handler(val) {
				this.openWindows=val;
				this.$emit("openWindows",val);
		    },
		    immediate: true,
		  },
		  
		},
		data() {
			return {
				
				content:'',
				openWindows:false,
			}
		},
		created() {
		 
		},
		methods: {
			
			subWrite(){
				var  data={
					consultationId:this.dataId,
					resultContent:this.content
				};
				var _this=this;
				config.ajaxAw("/kongtangweishi/api/consultation/updateMeetingResultContent",data,function(data){
					
					_this.$message({  message: '填写完成',type: 'success'});
					
					_this.openWindows=false;
					
				});
				
				
			}
			
		}
}
	
</script>

<style>
	.bootom_panel{
		margin: 10px;
		text-align: right;
	}
</style>