import request from '@/utils/HttpUtil'

function getLoginInfo(){
	
	
	return  JSON.parse(localStorage.getItem("userinfo"));

	
}

function ajaxAw(url,data,fun,show,err){
	
	 request({
	  url: url,
	  method: 'post',
	  
	  params: data
	}).then(res => {
		fun(res.data)
		
	})
	
}

function post(url,data,fun,show,err){
	
	request({
	  url: url,
	  method: 'post',
	  
	  data: data
	}).then(res => {
		fun(res.data)
		
	})
	
	 
	
}



export default {
	getLoginInfo,
	ajaxAw,
	post
}